import React from 'react';
import { Link } from "react-router-dom";
import './index.css';

function Footer() {
    return (
        <div className="footer">
            <p className="tip">
                抵制不良游戏，拒绝盗版游戏。
            </p>
            <p className="tip">
                注意自我保护，谨防上当受骗。
            </p>
            <p className="tip">
               适度游戏益脑，沉迷游戏伤身。
            </p>
             <p className="tip">
                合理安排时间，享受健康生活。
            </p>
            <p>
                Copyright © 2018 上海寻光长青网络科技有限公司 ALL Rights Reserved <a href="https://beian.miit.gov.cn/">沪ICP备2022028194号</a> 
            </p>
            <p>
                国新出审[2024]706号；ISBN：978-7-498-13336-6；软著登记号：2024SR0194684
            </p>
            <p>
                著作权人：上海掌梦网络科技有限公司；出版单位：上海科学技术文献出版社有限公司
            </p>
            
        </div>
    );
}

export default Footer;
